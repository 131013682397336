<template>
  <div id="login-container">
    <div class="login-card">
      <div class="form-container">
        <div class="header">بازیابی رمزعبور</div>
        <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
        <form class="login-form" @submit.prevent>
          <label
            class="input-label"
            :class="[
              this.$store.state.status === 'password' ? 'error' : '',
              this.message.password ? 'error' : ''
            ]"
          >
            <img src="../../assets/images/ri_lock-password-fill.svg" />
            <input v-model="password" type="password" placeholder="رمزعبور" />
          </label>
          <div
            v-if="this.$store.state.status === 'password' || message.password"
            class="error-massage"
          >
            {{ this.$store.state.message || this.message.password }}
          </div>
          <label
            class="input-label"
            :class="[
              this.$store.state.status === 'password' ? 'error' : '',
              this.message.confirm_password ? 'error' : ''
            ]"
          >
            <img src="../../assets/images/ri_lock-password-fill.svg" />
            <input
              v-model="password_confirm"
              type="password"
              placeholder="تکرار رمزعبور"
            />
          </label>
          <div
            v-if="
              this.$store.state.status === 'password' ||
                message.confirm_password
            "
            class="error-massage"
          >
            {{ this.$store.state.message || this.message.confirm_password }}
          </div>
          <button @click="changePassword" class="btn-singup">ورود</button>
          <button @click="register" class="btn-register">ثبت نام</button>
        </form>
      </div>
      <img
        class="login-image"
        src="../../assets/images/auth-image.svg"
        height="502"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      password: "",
      password_confirm: "",
      message: {
        password: "",
        confirm_password: ""
      }
    };
  },
  methods: {
    changePassword() {
      this.message = {};
      if (this.password !== this.password_confirm) {
        this.message.confirm_password = "رمز عبور با تکرار آن مطابقت ندارد";
      }
      if (this.password.length < 6) {
        this.message.password = "رمز عبور باید بیشتر از 6 کارکتر باشد";
      }
      if (!this.password) {
        this.message.password = "لطفا رمز عبور را وارد کنید";
      }
      if (!this.password_confirm) {
        this.message.confirm_password = "فیلد تکرار رمز عبور نباید خالی باشد";
      }
      let data = {
        password: this.password,
        password_confirmation: this.password_confirm
      };
      this.$store
        .dispatch("forgetPasswordRecovery", data)
        .then(() => {
          this.$router.push("/");
        })
        .catch(error => {
          console.log(error);
        });
    },
    register() {
      this.$router.push("/register");
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(248, 241, 241);
  opacity: 1;
}

#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0px 160px;
}

.login-image {
  position: absolute;
  left: -14%;
  top: -18%;
  width: 70%;
}

.form-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 6%;
  width: 88%;
  max-width: 400px;
  margin-bottom: 28px;
}

.header {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: 350px;
  height: 30px;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 20px;
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 10px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.input-label img {
  height: 20px;
  padding-right: 10px;
}

.liner {
  margin-bottom: 5px;
}

.btn-singup {
  font-family: "Vazir Medium FD";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}

.btn-register {
  font-family: "Vazir Medium FD";
  margin-top: 5px;
  margin-bottom: 5px;
  width: 285px;
  height: 40px;
  background: #1f3c88;
  border: 1px solid #ffffff;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  cursor: pointer;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  margin-top: -9px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff6b6b;
  text-align: right;
  cursor: pointer;
  align-self: flex-start;
  margin-right: 24px;
}

/* responseiv section */
@media only screen and (max-width: 320px) {
  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-card {
    width: 90%;
    margin: auto;
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-image {
    display: none;
  }

  .header {
    font-size: 1.5em;
  }

  .liner {
    width: 100%;
  }

  .input-label {
    width: 80%;
  }

  .input-label-error {
    width: 80%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .btn-singup {
    width: 70%;
    font-size: 16px;
  }

  .btn-register {
    width: 70%;
    font-size: 16px;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
  }

  .form-container {
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
  }

  .text-information {
    margin-right: 60px;
    font-size: 0.5em;
  }

  .header {
    font-size: 1.5em;
  }

  .input-label {
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error {
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .forget-password {
    font-size: 0.5em;
  }

  .liner {
    width: 100%;
  }

  .btn-singup {
    width: 80%;
    font-size: 1em;
  }

  .btn-register {
    width: 80%;
    font-size: 1em;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: -13%;
    top: -13%;
    width: 65%;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    left: -11%;
    width: 60%;
    top: -8%;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }
}
</style>
